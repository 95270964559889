import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { titleTemplate } from '@/helpers/meta/title-template';

@Component
export default class KnowledgeGlossary extends Vue {
  metaInfo(): MetaInfo {
    return {
      title: titleTemplate(this.$t('meta.glossary.title').toString()),
    };
  }

  questions = {
    general: {
      items: [
        {
          question: this.$t('help.glossary.word1'),
          answer: this.$t('help.glossary.explanation1'),
        },
        {
          question: this.$t('help.glossary.word2'),
          answer: this.$t('help.glossary.explanation2'),
        },
        {
          question: this.$t('help.glossary.word3'),
          answer: this.$t('help.glossary.explanation3'),
        },
        {
          question: this.$t('help.glossary.word4'),
          answer: this.$t('help.glossary.explanation4'),
        },
        {
          question: this.$t('help.glossary.word5'),
          answer: this.$t('help.glossary.explanation5'),
        },
        {
          question: this.$t('help.glossary.word6'),
          answer: this.$t('help.glossary.explanation6'),
        },
        {
          question: this.$t('help.glossary.word7'),
          answer: this.$t('help.glossary.explanation7'),
        },
        {
          question: this.$t('help.glossary.word8'),
          answer: this.$t('help.glossary.explanation8'),
        },
        {
          question: this.$t('help.glossary.word9'),
          answer: this.$t('help.glossary.explanation9'),
        },
        {
          question: this.$t('help.glossary.word10'),
          answer: this.$t('help.glossary.explanation10'),
        },
        {
          question: this.$t('help.glossary.word11'),
          answer: this.$t('help.glossary.explanation11'),
        },
        {
          question: this.$t('help.glossary.word12'),
          answer: this.$t('help.glossary.explanation12'),
        },
        {
          question: this.$t('help.glossary.word13'),
          answer: this.$t('help.glossary.explanation13'),
        },
        {
          question: this.$t('help.glossary.word14'),
          answer: this.$t('help.glossary.explanation14'),
        },
        {
          question: this.$t('help.glossary.word15'),
          answer: this.$t('help.glossary.explanation15'),
        },
        {
          question: this.$t('help.glossary.word16'),
          answer: this.$t('help.glossary.explanation16'),
        },
        {
          question: this.$t('help.glossary.word17'),
          answer: this.$t('help.glossary.explanation17'),
        },
        {
          question: this.$t('help.glossary.word18'),
          answer: this.$t('help.glossary.explanation18'),
        },
        {
          question: this.$t('help.glossary.word19'),
          answer: this.$t('help.glossary.explanation19'),
        },
      ],
    },
  }

  openQuestion: null | string = null

  toggleQuestion(catIndex: number, questionIndex: number): void {
    const newlySelected = `${catIndex}.${questionIndex}`;

    if (this.openQuestion && this.openQuestion === newlySelected) {
      this.openQuestion = null;
    } else {
      this.openQuestion = newlySelected;
    }
  }
}
