import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { titleTemplate } from '@/helpers/meta/title-template';

@Component
export default class KnowledgeFaq extends Vue {
  metaInfo(): MetaInfo {
    return {
      title: titleTemplate(this.$t('meta.faq.title').toString()),
    };
  }

  questions = {
    realEstate: {
      title: this.$t('help.faq.realEstateTitle'),
      items: [
        { question: this.$t('help.faq.realEstateQuestion1'), answer: this.$t('help.faq.realEstateAnswer1') },
        { question: this.$t('help.faq.realEstateQuestion2'), answer: this.$t('help.faq.realEstateAnswer2') },
        { question: this.$t('help.faq.realEstateQuestion3'), answer: this.$t('help.faq.realEstateAnswer3') },
        { question: this.$t('help.faq.realEstateQuestion4'), answer: this.$t('help.faq.realEstateAnswer4') },
      ],
    },
    account: {
      title: this.$t('help.faq.accountTitle'),
      items: [
        { question: this.$t('help.faq.accountQuestion1'), answer: this.$t('help.faq.accountAnswer1') },
        { question: this.$t('help.faq.accountQuestion2'), answer: this.$t('help.faq.accountAnswer2') },
        { question: this.$t('help.faq.accountQuestion3'), answer: this.$t('help.faq.accountAnswer3') },
        { question: this.$t('help.faq.accountQuestion4'), answer: this.$t('help.faq.accountAnswer4') },
        { question: this.$t('help.faq.accountQuestion5'), answer: this.$t('help.faq.accountAnswer5') },
        { question: this.$t('help.faq.accountQuestion6'), answer: this.$t('help.faq.accountAnswer6') },
        { question: this.$t('help.faq.accountQuestion7'), answer: this.$t('help.faq.accountAnswer7') },
      ],
    },
    invest: {
      title: this.$t('help.faq.investTitle'),
      items: [
        { question: this.$t('help.faq.investQuestion1'), answer: this.$t('help.faq.investAnswer1') },
        { question: this.$t('help.faq.investQuestion2'), answer: this.$t('help.faq.investAnswer2') },
        { question: this.$t('help.faq.investQuestion3'), answer: this.$t('help.faq.investAnswer3') },
        { question: this.$t('help.faq.investQuestion4'), answer: this.$t('help.faq.investAnswer4') },
        { question: this.$t('help.faq.investQuestion5'), answer: this.$t('help.faq.investAnswer5') },
        { question: this.$t('help.faq.investQuestion6'), answer: this.$t('help.faq.investAnswer6') },
      ],
    },
    sell: {
      title: this.$t('help.faq.sellTitle'),
      items: [
        { question: this.$t('help.faq.sellQuestion1'), answer: this.$t('help.faq.sellAnswer1') },
        { question: this.$t('help.faq.sellQuestion2'), answer: this.$t('help.faq.sellAnswer2') },
        { question: this.$t('help.faq.sellQuestion3'), answer: this.$t('help.faq.sellAnswer3') },
        { question: this.$t('help.faq.sellQuestion4'), answer: this.$t('help.faq.sellAnswer4') },
      ],
    },
    fiscal: {
      title: this.$t('help.faq.fiscalTitle'),
      items: [
        { question: this.$t('help.faq.fiscalQuestion1'), answer: this.$t('help.faq.fiscalAnswer1') },
        { question: this.$t('help.faq.fiscalQuestion2'), answer: this.$t('help.faq.fiscalAnswer2') },
        { question: this.$t('help.faq.fiscalQuestion3'), answer: this.$t('help.faq.fiscalAnswer3') },
      ],
    },
  }

  openQuestion: null | string = null

  toggleQuestion(catIndex: number, questionIndex: number): void {
    const newlySelected = `${catIndex}.${questionIndex}`;

    if (this.openQuestion && this.openQuestion === newlySelected) {
      this.openQuestion = null;
    } else {
      this.openQuestion = newlySelected;
    }
  }
}
